var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.model,
            expression: "model",
          },
        ],
        staticClass: "model",
      },
      [
        _c(
          "div",
          {
            staticClass: "model-show",
            on: {
              click: function ($event) {
                _vm.model = false
              },
            },
          },
          [
            _c("img", {
              attrs: { src: _vm.modelSrc, alt: "" },
              on: {
                click: function ($event) {
                  _vm.model = false
                },
              },
            }),
          ]
        ),
      ]
    ),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "show-info" }, [
        _c(
          "div",
          { staticClass: "test test1" },
          [
            _c("vueCropper", {
              ref: "cropper",
              attrs: {
                img: _vm.option.img,
                outputSize: _vm.option.size,
                outputType: _vm.option.outputType,
                info: true,
                full: _vm.option.full,
                canMove: _vm.option.canMove,
                canMoveBox: _vm.option.canMoveBox,
                fixedBox: _vm.option.fixedBox,
                original: _vm.option.original,
                autoCrop: _vm.option.autoCrop,
                autoCropWidth: _vm.option.autoCropWidth,
                autoCropHeight: _vm.option.autoCropHeight,
                centerBox: _vm.option.centerBox,
                high: _vm.option.high,
                infoTrue: _vm.option.infoTrue,
                maxImgSize: _vm.option.maxImgSize,
                enlarge: _vm.option.enlarge,
                mode: _vm.option.mode,
                limitMinSize: _vm.option.limitMinSize,
              },
              on: {
                realTime: _vm.realTime,
                imgLoad: _vm.imgLoad,
                cropMoving: _vm.cropMoving,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "test-button" }, [
          _c("button", { staticClass: "btn", on: { click: _vm.changeImg } }, [
            _vm._v("changeImg"),
          ]),
          _c("label", { staticClass: "btn", attrs: { for: "uploads" } }, [
            _vm._v("upload"),
          ]),
          _c("input", {
            ref: "uploadImg",
            staticStyle: { position: "absolute", clip: "rect(0 0 0 0)" },
            attrs: {
              type: "file",
              id: "uploads",
              accept: "image/png, image/jpeg, image/gif, image/jpg",
            },
            on: {
              change: function ($event) {
                return _vm.uploadImg($event, 1)
              },
            },
          }),
          !_vm.crap
            ? _c(
                "button",
                { staticClass: "btn", on: { click: _vm.startCrop } },
                [_vm._v("start")]
              )
            : _c(
                "button",
                { staticClass: "btn", on: { click: _vm.stopCrop } },
                [_vm._v("stop")]
              ),
          _c("button", { staticClass: "btn", on: { click: _vm.clearCrop } }, [
            _vm._v("clear"),
          ]),
          _c("button", { staticClass: "btn", on: { click: _vm.refreshCrop } }, [
            _vm._v("refresh"),
          ]),
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.changeScale(1)
                },
              },
            },
            [_vm._v("+")]
          ),
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.changeScale(-1)
                },
              },
            },
            [_vm._v("-")]
          ),
          _c("button", { staticClass: "btn", on: { click: _vm.rotateLeft } }, [
            _vm._v("rotateLeft"),
          ]),
          _c("button", { staticClass: "btn", on: { click: _vm.rotateRight } }, [
            _vm._v("rotateRight"),
          ]),
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.finish("base64")
                },
              },
            },
            [_vm._v("preview(base64)")]
          ),
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.finish("blob")
                },
              },
            },
            [_vm._v("preview(blob)")]
          ),
          _c(
            "button",
            { staticClass: "btn", on: { click: () => (_vm.option.img = "") } },
            [_vm._v("清除图片")]
          ),
          _c(
            "a",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.down("base64")
                },
              },
            },
            [_vm._v("download(base64)")]
          ),
          _c(
            "a",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.down("blob")
                },
              },
            },
            [_vm._v("download(blob)")]
          ),
          _c("a", {
            ref: "downloadDom",
            attrs: { href: _vm.downImg, download: "demo.png" },
          }),
        ]),
        _c("div", { staticClass: "pre" }, [
          _c("section", { staticClass: "pre-item" }, [
            _c("p", [_vm._v("截图框大小")]),
            _c(
              "div",
              {
                staticClass: "show-preview",
                style: {
                  width: _vm.previews.w + "px",
                  height: _vm.previews.h + "px",
                  overflow: "hidden",
                  margin: "5px",
                },
              },
              [
                _c("div", { style: _vm.previews.div }, [
                  _c("img", {
                    style: _vm.previews.img,
                    attrs: { src: _vm.previews.url },
                  }),
                ]),
              ]
            ),
          ]),
          _c("section", { staticClass: "pre-item" }, [
            _c("p", [_vm._v("中等大小")]),
            _c("div", { style: _vm.previewStyle1 }, [
              _c("div", { style: _vm.previews.div }, [
                _c("img", {
                  style: _vm.previews.img,
                  attrs: { src: _vm.previews.url },
                }),
              ]),
            ]),
          ]),
          _c("section", { staticClass: "pre-item" }, [
            _c("p", [_vm._v("迷你大小")]),
            _c("div", { style: _vm.previewStyle2 }, [
              _c("div", { style: _vm.previews.div }, [
                _c("img", {
                  style: _vm.previews.img,
                  attrs: { src: _vm.previews.url },
                }),
              ]),
            ]),
          ]),
          _c(
            "section",
            {
              staticClass: "pre-item",
              attrs: { title: "zoom: (100 / previews.w)" },
            },
            [
              _c("p", [_vm._v("固定为100宽度")]),
              _c("div", { style: _vm.previewStyle3 }, [
                _c("div", { style: _vm.previews.div }, [
                  _c("img", {
                    style: _vm.previews.img,
                    attrs: { src: _vm.previews.url },
                  }),
                ]),
              ]),
            ]
          ),
          _c(
            "section",
            {
              staticClass: "pre-item",
              attrs: { title: "zoom: (100 / previews.h)" },
            },
            [
              _c("p", [_vm._v("固定为100高度")]),
              _c("div", { style: _vm.previewStyle4 }, [
                _c("div", { style: _vm.previews.div }, [
                  _c("img", {
                    style: _vm.previews.img,
                    attrs: { src: _vm.previews.url },
                  }),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticStyle: { display: "block", width: "100%" } }, [
          _c("label", { staticClass: "c-item" }, [
            _c("span", [_vm._v("图片默认渲染方式")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.option.mode,
                    expression: "option.mode",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.option,
                      "mode",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "contain" } }, [
                  _vm._v("contain"),
                ]),
                _c("option", { attrs: { value: "cover" } }, [_vm._v("cover")]),
                _c("option", { attrs: { value: "400px auto" } }, [
                  _vm._v("400px auto"),
                ]),
                _c("option", { attrs: { value: "auto 400px" } }, [
                  _vm._v("auto 400px"),
                ]),
                _c("option", { attrs: { value: "50%" } }, [_vm._v("50%")]),
                _c("option", { attrs: { value: "auto 50%" } }, [
                  _vm._v("auto 50%"),
                ]),
              ]
            ),
            _c("section", [
              _vm._v(
                "\n              类似css background属性设置  设置不符合规范不生效， 参照文档说明\n            "
              ),
            ]),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [
              _vm._v("上传时图片最大大小(默认会压缩尺寸到这个大小)"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.maxImgSize,
                  expression: "option.maxImgSize",
                },
              ],
              attrs: { type: "nubmer" },
              domProps: { value: _vm.option.maxImgSize },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.option, "maxImgSize", $event.target.value)
                },
              },
            }),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [
              _vm._v("上传图片是否显示原始宽高 (针对大图 可以铺满)"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.original,
                  expression: "option.original",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.option.original)
                  ? _vm._i(_vm.option.original, null) > -1
                  : _vm.option.original,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.option.original,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.option, "original", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.option,
                          "original",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.option, "original", $$c)
                  }
                },
              },
            }),
            _c("span", [_vm._v("original: " + _vm._s(_vm.option.original))]),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [_vm._v("是否根据dpr生成适合屏幕的高清图片")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.high,
                  expression: "option.high",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.option.high)
                  ? _vm._i(_vm.option.high, null) > -1
                  : _vm.option.high,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.option.high,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.option, "high", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.option,
                          "high",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.option, "high", $$c)
                  }
                },
              },
            }),
            _c("span", [_vm._v("high: " + _vm._s(_vm.option.high))]),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [_vm._v("是否输出原图比例的截图")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.full,
                  expression: "option.full",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.option.full)
                  ? _vm._i(_vm.option.full, null) > -1
                  : _vm.option.full,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.option.full,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.option, "full", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.option,
                          "full",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.option, "full", $$c)
                  }
                },
              },
            }),
            _c("span", [_vm._v("full: " + _vm._s(_vm.option.full))]),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [_vm._v("截图信息展示是否是真实的输出宽高")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.infoTrue,
                  expression: "option.infoTrue",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.option.infoTrue)
                  ? _vm._i(_vm.option.infoTrue, null) > -1
                  : _vm.option.infoTrue,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.option.infoTrue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.option, "infoTrue", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.option,
                          "infoTrue",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.option, "infoTrue", $$c)
                  }
                },
              },
            }),
            _c("span", [_vm._v("infoTrue: " + _vm._s(_vm.option.infoTrue))]),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [_vm._v("能否拖动图片")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.canMove,
                  expression: "option.canMove",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.option.canMove)
                  ? _vm._i(_vm.option.canMove, null) > -1
                  : _vm.option.canMove,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.option.canMove,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.option, "canMove", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.option,
                          "canMove",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.option, "canMove", $$c)
                  }
                },
              },
            }),
            _c("span", [_vm._v("canMove: " + _vm._s(_vm.option.canMove))]),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [_vm._v("能否拖动截图框")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.canMoveBox,
                  expression: "option.canMoveBox",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.option.canMoveBox)
                  ? _vm._i(_vm.option.canMoveBox, null) > -1
                  : _vm.option.canMoveBox,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.option.canMoveBox,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.option, "canMoveBox", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.option,
                          "canMoveBox",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.option, "canMoveBox", $$c)
                  }
                },
              },
            }),
            _c("span", [
              _vm._v("canMoveBox: " + _vm._s(_vm.option.canMoveBox)),
            ]),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [_vm._v("截图框固定大小")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.fixedBox,
                  expression: "option.fixedBox",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.option.fixedBox)
                  ? _vm._i(_vm.option.fixedBox, null) > -1
                  : _vm.option.fixedBox,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.option.fixedBox,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.option, "fixedBox", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.option,
                          "fixedBox",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.option, "fixedBox", $$c)
                  }
                },
              },
            }),
            _c("span", [_vm._v("fixedBox: " + _vm._s(_vm.option.fixedBox))]),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [_vm._v("是否自动生成截图框")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.autoCrop,
                  expression: "option.autoCrop",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.option.autoCrop)
                  ? _vm._i(_vm.option.autoCrop, null) > -1
                  : _vm.option.autoCrop,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.option.autoCrop,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.option, "autoCrop", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.option,
                          "autoCrop",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.option, "autoCrop", $$c)
                  }
                },
              },
            }),
            _c("span", [_vm._v("autoCrop: " + _vm._s(_vm.option.autoCrop))]),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [_vm._v("自动生成截图框的宽高")]),
            _c("span", [_vm._v("宽度:  ")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.autoCropWidth,
                  expression: "option.autoCropWidth",
                },
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.option.autoCropWidth },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.option, "autoCropWidth", $event.target.value)
                },
              },
            }),
            _c("span", [_vm._v("高度:  ")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.autoCropHeight,
                  expression: "option.autoCropHeight",
                },
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.option.autoCropHeight },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.option, "autoCropHeight", $event.target.value)
                },
              },
            }),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [
              _vm._v("截图框是否限制在图片里(只有在自动生成截图框时才能生效)"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.centerBox,
                  expression: "option.centerBox",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.option.centerBox)
                  ? _vm._i(_vm.option.centerBox, null) > -1
                  : _vm.option.centerBox,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.option.centerBox,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.option, "centerBox", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.option,
                          "centerBox",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.option, "centerBox", $$c)
                  }
                },
              },
            }),
            _c("span", [_vm._v("centerBox: " + _vm._s(_vm.option.centerBox))]),
          ]),
          _c("label", { staticClass: "c-item" }, [
            _c("span", [_vm._v("是否按照截图框比例输出 默认为1 ")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option.enlarge,
                  expression: "option.enlarge",
                },
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.option.enlarge },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.option, "enlarge", $event.target.value)
                },
              },
            }),
          ]),
          _c("p", [_vm._v("输出图片格式")]),
          _c("label", { staticClass: "c-item" }, [
            _c("label", [
              _vm._v("jpg  "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.option.outputType,
                    expression: "option.outputType",
                  },
                ],
                attrs: { type: "radio", name: "type", value: "jpeg" },
                domProps: { checked: _vm._q(_vm.option.outputType, "jpeg") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.option, "outputType", "jpeg")
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v("png  "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.option.outputType,
                    expression: "option.outputType",
                  },
                ],
                attrs: { type: "radio", name: "type", value: "png" },
                domProps: { checked: _vm._q(_vm.option.outputType, "png") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.option, "outputType", "png")
                  },
                },
              }),
            ]),
            _c("label", [
              _vm._v("webp "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.option.outputType,
                    expression: "option.outputType",
                  },
                ],
                attrs: { type: "radio", name: "type", value: "webp" },
                domProps: { checked: _vm._q(_vm.option.outputType, "webp") },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.option, "outputType", "webp")
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }